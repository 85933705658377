import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  BottomBarEstimate,
  Typography,
  Box,
  Button,
  SvgIcon,
} from 'components/UI'
import {
  redPoint,
  yellowPoint,
  greenPoint,
  purlplePoint,
  marks,
  icons,
} from 'assets'
import { ReactComponent as Dot } from './../../../../../assets/icons/dots/dot.svg'

import useStyles from './styles'
import useStylesBetter from './stylesBetter'
import {
  DesktopPage,
  OverlayLoader,
  PrivateLoader,
  ScrollWrapper,
} from 'components/templates'
import {
  ITEM_PRIORITY,
  ITEM_STATUS,
  ESTIMATE_PUBLIC_STATUS,
  ESTIMATE_VIEW,
  history,
  isEmpty,
  round,
  APPROVAL_TYPE,
  ITEM_CATEGORY_FOR_FILTERS,
  formatTimestamp,
  sortItemsByCategories,
  ITEM_CATEGORY,
  USER_TYPE,
  ESTIMATE_STATUS,
} from 'helpers'
import {
  getCampaigns,
  getEstimate,
  getEstimateItems,
  getEstimateItemsGroupBy,
  getUser,
  getUserId,
  getUserPrimaryEmail,
  showApprove,
} from 'ducks/selectors'
import {
  campaignsActions,
  discountsActions,
  estimateActions,
  estimateItemsActions,
  itemsActions,
} from '../../../../../ducks/actions'
import { Item } from '../../../../../ducks/types'
// import { useIsXsScreen, useIsLgScreen } from '../../../../../hooks'
import EstimateAlert from './EstimateAlert'
import ContactUsModal from './ContactUsModal'
import PriceDetail from './PriceDetail'
import JobLinkBanner from './JobLinkBanner'
import { BANNER_STATUS } from './JobLinkBanner/constants'
import NewEstimateModal from '../NewEstimateModal'
import MobileShareModal from './ShareModal/MobileShareModal'
import DesktopShareModal from './ShareModal/DesktopShareModal'
import { MaintenanceModalsContext } from '../../OrderMaintenance/Dashboard/context'
import Modal from 'components/UI/CustomUI/molecules/Modal'
import PerksModal from '../../OrderMaintenance/Dashboard/PerksModal'
import EstimateLeft from './EstimateLeft'
import NeedMoreInformation from './NeedMoreInformation'
import TypeViewsV2 from 'components/UI/CustomUI/molecules/TypeViewsV2'
import moment from 'moment'
import EstimatedItems from './EstimatedItems'
import RequestMoreItemsModal from './RequestMoreItemsModal'
import AdditionalItemsRequested from './AdditionalItemsRequested'
import NewItemsEstimated from './NewItemsEstimated'
import Cart from './Cart'
import SharedEstimateIndicator from './SharedEstimateIndicator'
import { isMobile } from 'react-device-detect'
import { CategoryIcon } from 'components/UI/CustomUI/molecules/CategoryIcon'
import { generatePdf } from './ItemsPdf'
import useQRCodesGenerator from 'hooks/useQRCodeGenerator'

const getApprovedColor = (item: string) => {
  switch (item) {
    case 'Approved':
      return greenPoint
    case 'Rejected':
      return redPoint
  }
}

const EstimateView = () => {
  const mounted = useRef(false)
  const userId = useSelector(getUserId)
  const userEmail = useSelector(getUserPrimaryEmail)
  const user = useSelector(getUser)
  const userIsHomeowner = [USER_TYPE.HOME_BUYER, USER_TYPE.HOMEOWNER].includes(
    user.clientType
  )

  const { id: estimateId } = history.getParamValues()
  const estimate = useSelector(getEstimate())
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const dispatch = useDispatch()
  const push = history.usePush()

  const [qrCode] = useQRCodesGenerator(
    `${window.location.origin}/p/estimates/${estimate?.id}`
  )
  const [tutorialVideoQrCode] = useQRCodesGenerator(
    `https://www.youtube.com/watch?v=5237HyFN44M`
  )

  const {
    properties,
    sharedByEmail,
    sharedByName,
    deliveredOn,
    approvedOn,
    promotionId,
  } = estimate ?? {}

  const {
    territory,
    address,
    contacts,
    minimum = 0,
    affiliate,
    discount,
    nar,
    taxAmount,
    totalWithTax = 0,
    taxRate,
  } = properties ?? {}
  /*   const {
    } = address ?? {} */
  const { serviceable } = territory ?? {}

  const {
    firstName = '',
    lastName = '',
    email = '',
  } = contacts && contacts.length > 0 ? contacts[0] : {}

  const contact = estimate?.properties?.contacts?.find(
    (contact) => contact.email === userEmail
  )

  const {
    line_1: street,
    line_2: street2,
    city,
    state,
    zipCode,
  } = address ?? {}

  const line1 = (street ? `${street} ` : '') + (street2 ? `#${street2}` : '')
  const line2 = `${city}, ${state} ${zipCode}`

  const showBetterStyle = !isEmpty(affiliate) && affiliate === 'better'
  const classes = showBetterStyle ? stylesBetter : styles
  const isPillarToPost =
    estimate?.properties.affiliate === 'pillartopost' ||
    estimate?.properties.affiliate === 'pillar to post'

  const [pageLoading, setPageLoading] = useState(true)
  const [itemsLoading, setItemsLoading] = useState(false)
  const [selectedType, setSelectedType] = useState<ESTIMATE_VIEW>(
    ESTIMATE_VIEW.TYPE
  )

  const [hideRemoved, setHideRemoved] = useState(false)
  const [downloadingPdf, setDownloadingPdf] = useState(false)
  const [openRequestItemModal, setOpenRequestItemModal] = useState(false)
  const [openShareModal, setOpenShareModal] = useState(false)
  const [openContactUsModal, setOpenContactUsModal] = useState(false)
  const [showFAQ, setShowFAQ] = useState(true)
  const [openCart, setOpenCart] = useState(false)
  const campaign = useSelector(getCampaigns())

  const isFromCanada = estimate?.properties.address?.country === 'Canada'

  const estimateItems = useSelector(getEstimateItems(false)).filter(
    (item: Item) => {
      return (
        !item.addedPostDelivery ||
        (item.addedPostDelivery && item.status !== ITEM_STATUS.NEW)
      )
    }
  )

  const groupedItems = sortItemsByCategories(
    useSelector(
      getEstimateItemsGroupBy(
        selectedType,
        hideRemoved,
        undefined,
        undefined,
        estimateItems
      )
    )
  )

  const groupedItemsForPdf = sortItemsByCategories(
    useSelector(getEstimateItemsGroupBy(selectedType, hideRemoved))
  )

  const evaluateItems = sortItemsByCategories(
    useSelector(getEstimateItemsGroupBy(ESTIMATE_VIEW.TYPE, false, undefined))
  )[ITEM_CATEGORY.EVALUATE]

  delete groupedItems.EVALUATE

  const [selectedFilt, setSelectedFilt] = useState<
    ITEM_CATEGORY_FOR_FILTERS | ITEM_PRIORITY
  >(ITEM_CATEGORY_FOR_FILTERS.ALL)

  const items = useSelector(getEstimateItems(false))

  const categoryHash = (items as Item[]).reduce((result: any, item: Item) => {
    const key =
      selectedType === ESTIMATE_VIEW.PRIORITY ? item.severity : item.category
    if (result[key] === undefined) result[key] = 1
    else result[key]++

    return result
  }, {})

  const sortedPriorities = Object.fromEntries(
    Object.entries(categoryHash).sort(
      (a: any, b: any) =>
        Object.values(ITEM_PRIORITY).indexOf(a[0]) -
        Object.values(ITEM_PRIORITY).indexOf(b[0])
    )
  )

  const categoryTabs = Object.keys(sortedPriorities).map((key) => ({
    title: key,
    count: categoryHash[key],
  }))

  const shared = estimate?.sharedByEmail != null
  const punchlistPlanner = serviceable !== undefined && !serviceable && !shared

  const bannerStatus = useMemo(() => {
    if (estimate?.jobNeedsAttention === true)
      return BANNER_STATUS.NEEDS_ATTENTION
    if (estimate?.publicStatus === ESTIMATE_PUBLIC_STATUS.APPROVED)
      return BANNER_STATUS.IN_PROGRESS
    return BANNER_STATUS.OTHER
  }, [estimate])

  const isHomewardCXSupport = [
    'cxsupport@test.com',
    'cxsupport@homeward.com',
  ].includes(userEmail)

  const estimateIsOutOfArea = !estimate?.properties.territory?.serviceable

  const showApproveFlow =
    (useSelector(showApprove(userEmail)) || isHomewardCXSupport) &&
    (totalWithTax || 0) > minimum

  const fetchEstimateItems = useCallback(
    (loadings = true) => {
      if (loadings) setItemsLoading(true)
      dispatch(
        estimateItemsActions.fetchEstimateItems(
          estimateId,
          (_succ: boolean) => {
            if (mounted.current) {
              setItemsLoading(false)
            } else {
              return null
            }
          }
        )
      )
    },
    [dispatch, estimateId]
  )

  const fetchDiscountBanner = useCallback(() => {
    const date = moment().format('MMM YYYY')

    dispatch(discountsActions.fetchDiscountBanner())
  }, [dispatch])

  const fetchCampaigns = useCallback(() => {
    dispatch(
      campaignsActions.fetchCampaigns({
        estimateType: !serviceable
          ? 'OUT_OF_AREA'
          : estimate?.formType === 'CLIENT_REPAIR_LIST'
            ? 'IN_AREA_REPAIR_LIST'
            : 'IN_AREA_ESTIMATE',
      })
    )
  }, [dispatch])

  const fetchEstimateView = useCallback(
    (loadings = true) => {
      if (loadings && mounted.current) setPageLoading(true)
      dispatch(
        estimateActions.fetchEstimate(
          estimateId,
          (_succ: boolean, estimateReturned) => {
            if (mounted.current) setPageLoading(false)
            if (!_succ) {
              push(`p/dashboard`)
              // goBack()
            } else {
              if (!estimateReturned?.deliveredOn) {
                push(`p/estimates`)
              }
              if (mounted.current) {
                fetchEstimateItems(loadings)
              }
            }
          }
        )
      )
      territory?.id &&
        dispatch(
          itemsActions.fetchRecommendedItems(territory?.id, (succ) => { })
        )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, estimateId, fetchEstimateItems]
  )

  useEffect(() => {
    fetchDiscountBanner()
    fetchCampaigns()
  }, [estimate])

  useEffect(() => {
    mounted.current = true
    fetchEstimateView()
    return () => {
      mounted.current = false
    }
  }, [fetchEstimateView])

  const handleSubmit = (type: ESTIMATE_VIEW): void => {
    setSelectedType(type)
  }

  const getIcon = (item: any, color?: string) => {
    switch (selectedType) {
      case ESTIMATE_VIEW.TYPE:
        return (
          <Box className={classes.icon}>
            <CategoryIcon title={item} color={color} />
          </Box>
        )
      case ESTIMATE_VIEW.PRIORITY:
        return (
          <Box className={classes.icon}>
            <CategoryIcon filteredByPriority title={item} color={color} />
          </Box>
        )
      case ESTIMATE_VIEW.APPROVED:
        return (
          <img src={getApprovedColor(item)} alt="" className={classes.icon} />
        )
    }
  }

  const handleApprove = (): void => {
    push(`p/estimates/${estimate?.id}/approve`)
  }

  const handleRemove = (
    estimateId: string,
    itemId: string,
    removed: boolean
  ): void => {
    dispatch(
      estimateItemsActions.updateEstimateItem(
        {
          estimateId,
          itemId,
          partialItem: {
            status: removed ? ITEM_STATUS.APPROVED : ITEM_STATUS.REJECTED,
          },
        },
        () => {
          fetchEstimateView(false)
        }
      )
    )
  }

  const handleShare = (): void => {
    setOpenShareModal(true)
  }

  const onOpenCart = (): void => {
    setOpenCart(true)
  }

  const ITEM_STATUS_IN_CART = [
    ITEM_STATUS.APPROVED,
    ITEM_STATUS.ACCEPTED,
    ITEM_STATUS.COMPLETE,
    ITEM_STATUS.COMPLETED,
  ]

  const filterApproved = (groupedItems: any) => {
    const filteredItems = { ...groupedItems }
    Object.keys(filteredItems).forEach((key) => {
      const approvedItems = filteredItems[key].filter((item: Item) =>
        ITEM_STATUS_IN_CART.includes(item.status)
      )
      if (approvedItems.length) {
        filteredItems[key] = approvedItems
      } else {
        delete filteredItems[key]
      }
    })
    return filteredItems
  }

  const onlyApprovedItems = filterApproved(groupedItems)

  const handlePdf = async (onlyApproved?: boolean) => {
    if (!downloadingPdf) {
      setDownloadingPdf(true)

      await generatePdf({
        groupedItems: onlyApproved ? onlyApprovedItems : groupedItemsForPdf,
        evaluateItems: evaluateItems,
        userInfo: {
          name: `${firstName} ${lastName}`,
          city: city ? `${city},` : '',
          address: address,
          sent: deliveredOn
            ? formatTimestamp(deliveredOn, 'MMMM Do, YYYY')
            : '',
          approvedOn: approvedOn
            ? formatTimestamp(approvedOn, 'MMMM Do, YYYY')
            : '',
          mail: email ?? '',
          taxRate: taxRate,
          taxAmount: taxAmount,
          nar: nar,
          total: total,
          isHomeowner: userIsHomeowner,
          serviceable,
        },
        imageUris: {
          qrCode, //qrcode here
          // tutorialVideoQrCode
        },
        marketingImg: campaign?.imageUrl,
        estimate: estimate,
      })
      setDownloadingPdf(false)
    }
  }

  const [alert, setAlert] = useState(false)
  const [alertItem, setAlertItem] = useState<Item | null>(null)

  const handleShowAlert = (item: Item): void => {
    setAlertItem(item)
    setAlert(true)
  }

  const estimateNotApproved =
    !shared && estimate?.publicStatus !== ESTIMATE_PUBLIC_STATUS.APPROVED

  const {
    state: { modalType, modalOpen },
    dispatch: dispatchContext,
  } = useContext(MaintenanceModalsContext)

  const minPriceRange = estimate?.minPriceRange
  const maxPriceRange = estimate?.maxPriceRange

  const total = useMemo(() => {
    if (minPriceRange && maxPriceRange) {
      if (minPriceRange === null || maxPriceRange === null) {
        return '$'
          .concat(round(totalWithTax ?? 0, 2))
          .concat(isFromCanada ? ' CAD' : '')
      }

      if (minPriceRange === maxPriceRange) {
        return '$'
          .concat(round(totalWithTax ?? 0, 2))
          .concat(isFromCanada ? ' CAD' : '')
      }

      if (minPriceRange !== maxPriceRange) {
        return '$'
          .concat(round(minPriceRange, 0).slice(0, -3))
          .concat(isFromCanada ? ' CAD' : '')
          .concat(' - $')
          .concat(round(maxPriceRange, 0).slice(0, -3))
          .concat(isFromCanada ? ' CAD' : '')
      }
    }
    return '$'
      .concat(round(totalWithTax ?? 0, 2))
      .concat(isFromCanada ? ' CAD' : '')
  }, [minPriceRange, maxPriceRange, totalWithTax, isFromCanada])

  const handleOpenContactUsModal = (showFAQ: boolean) => {
    setOpenContactUsModal(!openContactUsModal)
    setShowFAQ(showFAQ)
  }

  return (
    <PrivateLoader loading={pageLoading}>
      <OverlayLoader loading={downloadingPdf}>
        <DesktopPage customPadding="0" header={<div></div>}>
          {/* <PDFViewer style={{ minHeight: '100vh', minWidth: '100vw' }}>
            <GenerateDocument
              groupedItems={groupedItems}
              evaluateItems={evaluateItems}
              userInfo={{
                name: `${firstName} ${lastName}`,
                city: city ? `${city},` : '',
                address: address,
                sent: deliveredOn
                  ? formatTimestamp(deliveredOn, 'MMMM Do, YYYY')
                  : '',
                approvedOn: approvedOn
                  ? formatTimestamp(approvedOn, 'MMMM Do, YYYY')
                  : '',
                mail: email ?? '',
                taxRate: taxRate,
                taxAmount: taxAmount,
                nar: nar,
                total: total,
                isHomeowner: userIsHomeowner,
                serviceable,
              }}
              imageUris={{
                qrCode,
                // tutorialVideoQrCode
              }}
              marketingImg={campaign?.imageUrl}
              estimate={estimate}
            />
          </PDFViewer>  */}
          <div className={classes.container}>
            <div className={classes.containerLeft}>
              <EstimateLeft
                estimate={estimate}
                onDownloadPDF={handlePdf}
                onShare={handleShare}
                serviceable={serviceable}
                ptp={isPillarToPost}
                shared={shared}
              />
            </div>
            <div className={classes.containerRight}>
              <Modal
                open={openCart}
                setOpen={() => setOpenCart(!openCart)}
                size="xl"
                className={classes.cartModal}
                title={
                  <Box className={classes.cartHeader}>
                    <Box className={classes.cartTitleContainer}>
                      {isMobile && (
                        <icons.ArrowBack
                          className={classes.arrowBack}
                          onClick={() => setOpenCart(false)}
                        />
                      )}
                      <Box className={classes.row}>
                        <icons.ShoppingCart className={classes.cartIcon} />
                        <Typography className={classes.cartTitle}>
                          {serviceable
                            ? 'Cart Summary'
                            : 'Total for selected Items'}
                        </Typography>
                      </Box>
                      {/* <Typography className={classes.cartTitle}>${round(estimate?.properties?.totalWithTax || 0, 2)}</Typography> */}
                    </Box>
                    {isMobile && (
                      <Typography
                        className={classes.estimateValid}
                        variant="caption"
                      >
                        Estimate valid for 30 days
                      </Typography>
                    )}
                  </Box>
                }
                showClose={!isMobile}
              >
                <Cart
                  handleOpenContactUsModal={handleOpenContactUsModal}
                  fetchEstimateView={fetchEstimateView}
                  serviceable={serviceable} /* price={total} */
                  setOpenCart={() => setOpenCart(!openCart)}
                  onApprove={handleApprove}
                  showApproveButton={showApproveFlow}
                  price={total}
                  estimateNotApproved={estimateNotApproved}
                />
              </Modal>
              <Modal
                open={modalOpen && modalType === 'PERKS'}
                setOpen={() =>
                  dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
                }
                size="xl"
                className={classes.modal}
                title={
                  <Typography
                    variant="h5"
                    style={{ paddingLeft: 0, fontFamily: 'LatoNormal' }}
                  >
                    Four Seasons of Care List
                  </Typography>
                }
              >
                <PerksModal />
              </Modal>
              {!serviceable &&
                (estimate?.properties?.affiliate === 'pillartopost' ||
                  estimate?.properties?.affiliate === 'pillar to post') &&
                !shared && <NewEstimateModal />}

              <PrivateLoader loading={itemsLoading} building="fragment">
                <>
                  <TypeViewsV2
                    currentTab={0}
                    tabs={categoryTabs}
                    selectedType={selectedType}
                    handleSubmit={handleSubmit}
                    totalCount={items.length}
                    getIcon={getIcon}
                    // use the selectedFilt for filtering the items with selected category or priority
                    selectedFilt={selectedFilt}
                    setSelectedFilt={setSelectedFilt}
                  />
                  {shared && (
                    <SharedEstimateIndicator
                      sharedByEmail={sharedByEmail || ''}
                      sharedByName={sharedByName || ''}
                    />
                  )}
                  <ScrollWrapper relativeClass={classes.relativeContainer}>
                    <>
                      <JobLinkBanner
                        jobStatus={bannerStatus}
                        jobId={estimate?.id}
                      />

                      <NeedMoreInformation
                        estimateNotApproved={estimateNotApproved}
                        showBetterStyle={showBetterStyle}
                        punchlistPlanner={punchlistPlanner}
                        handleShowAlert={handleShowAlert}
                        handleRemove={handleRemove}
                        serviceable={serviceable}
                        fetchEstimateView={fetchEstimateView}
                        selectedFilt={selectedFilt}
                      />

                      {estimate && estimate?.properties?.clientRequestNotes && (
                        <AdditionalItemsRequested
                          punchlistPlanner={punchlistPlanner}
                          handleShowAlert={handleShowAlert}
                          handleRemove={handleRemove}
                          serviceable={serviceable}
                          estimateNotApproved={estimateNotApproved}
                        />
                      )}

                      {/* {selectedFilt === ITEM_CATEGORY_FOR_FILTERS.ALL && */}
                      <NewItemsEstimated
                        selectedType={selectedType}
                        hideRemoved={hideRemoved}
                        serviceable={serviceable}
                        selectedFilt={selectedFilt}
                        fetchEstimateView={fetchEstimateView}
                        handleShowAlert={handleShowAlert}
                        punchlistPlanner={punchlistPlanner}
                        estimateNotApproved={estimateNotApproved}
                        getIcon={getIcon}
                      />
                      {/* } */}
                      <EstimatedItems
                        selectedType={selectedType}
                        hideRemoved={hideRemoved}
                        serviceable={serviceable}
                        selectedFilt={selectedFilt}
                        fetchEstimateView={fetchEstimateView}
                        handleShowAlert={handleShowAlert}
                        punchlistPlanner={punchlistPlanner}
                        estimateNotApproved={estimateNotApproved}
                        getIcon={getIcon}
                      />
                      {isMobile &&
                        !estimate?.properties?.clientRequestNotes && !estimateIsOutOfArea &&
                        estimateNotApproved && (
                          <Box
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              size="small"
                              className={classes.requestMoreButton}
                              fullWidth
                              onClick={() => setOpenRequestItemModal(true)}
                              startIcon={<SvgIcon component={icons.ListAlt} />}
                            >
                              <Typography> Request More Items</Typography>
                            </Button>
                          </Box>
                        )}
                    </>
                  </ScrollWrapper>

                  {alert && alertItem && (
                    <Modal
                      open={alert}
                      setOpen={() => setAlert(!alert)}
                      className={classes.itemDetailModal}
                    >
                      <Box className={classes.back}>
                        <Box className={classes.alert}>
                          <EstimateAlert
                            title={alertItem.title}
                            quantity={alertItem.quantity}
                            inspectionReportNote={
                              alertItem.inspectionReportNote
                            }
                            requestedRepairNote={alertItem.requestedRepairNote}
                            price={alertItem.totalPrice}
                            imageFiles={alertItem.imageFiles}
                            disclaimer={alertItem.disclaimer}
                            removed={alertItem.status === ITEM_STATUS.REJECTED}
                            showButtons={estimateNotApproved}
                            onRemove={() =>
                              handleRemove(
                                alertItem.estimateId,
                                alertItem.itemId,
                                alertItem.status === ITEM_STATUS.REJECTED
                              )
                            }
                            showAlert={(value) => setAlert(value)}
                          />
                        </Box>
                      </Box>
                    </Modal>
                  )}

                  {!isMobile ? (
                    <DesktopShareModal
                      open={openShareModal}
                      setOpen={setOpenShareModal}
                      showBetterStyle={showBetterStyle}
                    />
                  ) : (
                    <MobileShareModal
                      open={openShareModal}
                      setOpen={setOpenShareModal}
                      showBetterStyle={showBetterStyle}
                    />
                  )}

                  <ContactUsModal
                    open={openContactUsModal}
                    showFAQ={showFAQ}
                    setOpen={setOpenContactUsModal}
                    showBetterStyle={showBetterStyle}
                  />

                  <RequestMoreItemsModal
                    open={openRequestItemModal}
                    setOpen={setOpenRequestItemModal}
                  />

                  {!isMobile && (
                    <PriceDetail
                      discount={discount}
                      isNar={nar}
                      taxAmount={taxAmount}
                      totalValue={totalWithTax}
                      taxRate={taxRate}
                      priceRange={total}
                      serviceable={serviceable}
                      totalPrice={total}
                      ptp={isPillarToPost}
                      discountText={promotionId || ''}
                    />
                  )}

                  <BottomBarEstimate
                    disabledForward={!totalWithTax || totalWithTax < minimum}
                    onRequestMoreItems={() => setOpenRequestItemModal(true)}
                    onOpenCart={onOpenCart}
                    rmForward={false}
                    price={total}
                    showButtons={estimateNotApproved}
                    showBetterStyle={showBetterStyle}
                    showApproveButton={showApproveFlow}
                    isPunchlistPlanner={punchlistPlanner}
                    shared={shared}
                    serviceable={serviceable}
                    ptp={isPillarToPost}
                  />
                </>
              </PrivateLoader>
            </div>
          </div>
        </DesktopPage>
      </OverlayLoader>
    </PrivateLoader>
  )
}

export default EstimateView
