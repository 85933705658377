import { Box, ListItem, ListItemIcon, ListItemProps, ListItemText, SvgIcon, Typography } from "@material-ui/core"
import { history } from "helpers/index"
import { FC, useState } from "react"
import { OptionType } from "../types"

import useStyles from './styles'

interface MenuItemProps extends ListItemProps {
  key: number | string
  option: OptionType
  registerCompleted: boolean
  setOpen: (open: boolean) => void
  open: boolean
}

const MenuItem: FC<MenuItemProps> = ({
  key,
  option,
  registerCompleted,
  setOpen,
  open,
  ...props
}) => {
  const classes = useStyles()

  const [collapsed, setCollapsed] = useState<boolean>(false)

  const currentPath = history.getCurrentPath()
  const push = history.usePush()

  const handleListItemClick = (option: any, isSubOption: boolean = false) => {
    if (option.subOptions) {
      setCollapsed(!open)
      setOpen(true)
      return
    }
    if (option.onClick) {
      option.onClick()
    }
    if (collapsed && isSubOption) setOpen(false)
    push('p/' + option.path)
    // setOpen(false)
  }

  const isCurrentPath = currentPath?.replace('/p/', '') === option.path

  return (
    <>
      <ListItem
        key={key}
        button
        disabled={option.disabled || !registerCompleted}
        className={
          isCurrentPath
            ? classes.selectedOption
            : classes.option
        }
        onClick={() => handleListItemClick(option)}
      >
        <ListItemIcon className={`${classes.icon} ${isCurrentPath ? classes.iconWithoutLeft : ''}`}>
          <SvgIcon
            component={option?.icon}
            style={{
              color:
                isCurrentPath
                  ? 'var(--input-color)'
                  : 'inherit',
            }}
          />
        </ListItemIcon>
        <ListItemText
          style={{ margin: 0 }}
          primary={
            <Typography
              style={{
                fontSize: '18px',
                fontWeight:
                  isCurrentPath
                    ? 900
                    : 400,
              }}
            >
              {option.text}
            </Typography>
          }
        />
        {/* {option.subOptions && option.subOptions.length > 0 && (
        <Box>
          {option.subOptions.map((subOption, index) => (
            <Typography>
              {subOption.text}
            </Typography>
          ))}
        </Box>
      )} */}
      </ListItem>
      {open && option.subOptions && option.subOptions.map(suboption =>
        <ListItem
          key={key}
          button
          disabled={suboption.disabled || !registerCompleted}
          className={
            currentPath?.replace('/p/', '') === suboption.path
              ? classes.selectedSubOption
              : classes.suboption
          }
          onClick={() => suboption.path && handleListItemClick(suboption, true)}
        >
          {suboption?.icon &&
            <ListItemIcon className={classes.icon}>
              <SvgIcon
                component={suboption?.icon}
                style={{
                  width: '.7rem',
                  marginRight: '.5rem',
                  color:
                    currentPath?.replace('/p/', '') === suboption.path
                      ? 'var(--input-color)'
                      : 'inherit',
                }}
              />
            </ListItemIcon>
          }
          <ListItemText
            primary={
              <Typography
                style={{
                  fontWeight:
                    currentPath?.replace('/p/', '') === suboption.path
                      ? 900
                      : 400,
                }}
              >
                {suboption.text}
              </Typography>
            }
          />
          {/* {option.subOptions && option.subOptions.length > 0 && (
            <Box>
              {option.subOptions.map((subOption, index) => (
                <Typography>
                  {subOption.text}
                </Typography>
              ))}
            </Box>
          )} */}
        </ListItem>
      )
      }
    </>
  )
}

export default MenuItem