import { FC, useState, useEffect, useCallback } from 'react'
import { Box, SideMenu, UserMenu } from '../../UI'
import { icons, calendar, homeBase } from '../../../assets'
import useStyles from './styles'
import { TemplateProps } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { getUser, getUserPrimaryEmail, isAuthorized } from '../../../ducks/selectors'
import { USER_TYPE } from 'helpers/constants'
import { subscriptionsActions } from 'ducks/actions'
import { getSubscriptionsValue, territoryIsAvailableForMaintenance } from 'ducks/subscriptions/selectors'
import { history, pathsToHideSideMenu } from 'helpers/index'
import HouseIcon from '@material-ui/icons/House'
import { TrackGoogleAnalyticsEvent } from 'helpers/google-analytics'

const Template: FC<TemplateProps> = ({ children, showEstimateOptions = true, menuColor }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const userPrimaryEmail = useSelector(getUserPrimaryEmail)
  const user = useSelector(getUser)
  const [avatar, setAvatar] = useState(user.picture)
  const authorized = useSelector(isAuthorized)
  const subscriptionsPlans = useSelector(getSubscriptionsValue('plans'))
  const showOrderMaintenance = [USER_TYPE.BROKER, USER_TYPE.INSPECTOR, USER_TYPE.INSTITUTIONAL, USER_TYPE.HOME_BUYER, USER_TYPE.HOMEOWNER, USER_TYPE.CLOSING_COORDINATOR, USER_TYPE.PROPERTY_MANAGER, USER_TYPE.OTHER].includes(user.clientType)
  const currentPath = history.getCurrentPath()

  const hideSideMenu = pathsToHideSideMenu.some(path => currentPath?.includes(path))

  const updateUserAvatar = (
    prop: CustomEventInit<{
      newPicture: string
    }>
  ) => {
    setAvatar(prop.detail?.newPicture ?? '')
  }

  useEffect(() => {
    setAvatar(user.picture)
    subscriptionsPlans.length <= 0 && authorized && fetchPlans()
  }, [user])

  const fetchPlans = useCallback(() => {
    dispatch(subscriptionsActions.fetchSubscriptionsPlans((fetchSuccess, plans) => {
    }))
  }, [dispatch])

  const options = [
    {
      text: <Box display='flex' alignItems='center'><img src={homeBase} alt="homebase" style={{ height: '16px' }} color='inherit' /></Box>,
      title: 'HOMEBASE',
      icon: icons.House,
      path: 'dashboard',
    },
    {
      text: 'Estimates',
      title: 'Estimates',
      icon: icons.Assignment,
      // path: 'estimates',
      subOptions: [
        {
          text: 'My Estimates',
          icon: icons.ArrowForwardIos,
          path: 'estimates',
          // variant: 'contained'
        },
        {
          text: 'Shared Estimates',
          // icon: icons.Assignment,
          path: 'shared-estimates',
          // variant: 'contained'
        },
      ]
    },
    {
      text: 'Jobs',
      title: 'Jobs',
      icon: icons.Build,
      path: 'jobs'
    },
     {
       text: 'HOMEMART',
       title: 'HOMEMART',
       icon: icons.ShoppingCart,
       path: 'homemart',
     },
    {
      text: 'Payments',
      title: 'Payments',
      icon: icons.CreditCard,
      path: 'payments',
    }
     /*,
    {
      text: 'Properties',
      title: 'Properties',
      icon: icons.House,
      path: 'properties'
    } */,
  ]

  const estimateOptions = {
    icon: calendar,
    title: 'Get an Estimate',
    options: [
      {
        text: 'Upload Inspection PDF',
        variant: 'contained' as 'contained',
        //path: 'getEstimate'
        path: 'estimate?authorized=true',
      },
      {
        text: 'Create Repair List',
        variant: 'outlined' as 'outlined',
        //path: 'repairList'
        path: 'estimate?authorized=true',
      },
    ],
  }

  useEffect(() => {
    document.addEventListener('profilePictureChange', updateUserAvatar)
    return document.removeEventListener('profilePictureChange', () => { })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Box className={authorized ? classes.root : ''}>
      {authorized && !hideSideMenu && <UserMenu avatar={avatar} />}
      {authorized && <SideMenu menuColor={menuColor} options={options} estimateOptions={showEstimateOptions ? estimateOptions : undefined} showOrderMaintenance={showOrderMaintenance} />}
      {children}
    </Box>
  )
}

export default Template
